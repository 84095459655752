<template>
  <component :is="profileData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="profileData === undefined">
      <h4 class="alert-heading">Error fetching profile data</h4>
      <div class="alert-body">
        No profile found with this profile id. Check
        <b-link class="alert-link" :to="{ name: 'apps-roster-profile-list' }"> Profile List </b-link>
        for other profiles.
      </div>
    </b-alert>

    <b-tabs v-if="profileData" pills lazy>
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon icon="PaperclipIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Attributes</span>
        </template>
        <profile-edit-tab-attributes :profile-data.sync="profileData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Certificates -->
      <b-tab>
        <template #title>
          <feather-icon icon="LayersIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Certifications</span>
        </template>
        <profile-edit-tab-certificates :profile-data.sync="profileData" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Notes -->
      <b-tab>
        <template #title>
          <feather-icon icon="Edit2Icon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Notes</span>
        </template>
        <profile-edit-tab-notes :profile-data.sync="profileData" class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink, BCol } from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import placementStoreModule from '../placementStoreModule';
import ProfileEditTabInformation from './tabs/ProfileEditTabInformation.vue';
import ProfileEditTabAttributes from './tabs/ProfileEditTabAttributes';
import ProfileEditTabCertificates from './tabs/ProfileEditTabCertificates';
import ProfileEditTabEmails from './tabs/ProfileEditTabEmails';
import ProfileEditTabPhoneNumbers from './tabs/ProfileEditTabPhoneNumbers';
import ProfileEditTabNotes from '@/views/apps/placement/profile-edit/tabs/ProfileEditTabNotes';

export default {
  components: {
    ProfileEditTabNotes,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BCol,

    ProfileEditTabCertificates,

    ProfileEditTabAttributes,
    ProfileEditTabInformation,
    ProfileEditTabEmails,
    ProfileEditTabPhoneNumbers,
  },
  setup() {
    const profileData = ref(null);

    const PROFILE_APP_STORE_MODULE_NAME = 'app-placement';

    // Register module
    if (!store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROFILE_APP_STORE_MODULE_NAME, placementStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PROFILE_APP_STORE_MODULE_NAME);
      }
    });

    store
      .dispatch('app-placement/fetchProfile', { id: router.currentRoute.params.id })
      .then((response) => {
        profileData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          profileData.value = undefined;
        }
      });

    return {
      profileData,
    };
  },
};
</script>

<style></style>
