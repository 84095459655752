<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="ProfileIcon" size="19" />
      <h4 class="mb-0">Phone Numbers</h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <!-- Form: Personal Info Form -->
      <b-form ref="form" @submit.prevent="repeateAgain">
        <h5 v-if="phoneNumbers.length === 0" class="text-center">No phone numbers listed</h5>

        <!-- Row Loop -->
        <b-row v-for="(item, index) in phoneNumbers" :id="item.id" :key="item.id" ref="row">
          <!-- Item Name -->
          <b-col lg="3" md="3" sm="12">
            <b-form-group label="Number Label" :label-for="`number-label-${index}`">
              <b-form-input :id="`number-label-${index}`" type="text" placeholder="Cell Phone" v-model="item.label" />
            </b-form-group>
          </b-col>

          <!-- Phone Number -->
          <b-col lg="5" md="5" sm="12">
            <b-form-group label="Phone Number" :label-for="`number-number-${index}`">
              <cleave
                :id="`number-number-${index}`"
                v-model="item.number"
                class="form-control"
                :raw="false"
                :options="{ prefix: '+1', phone: true, phoneRegionCode: 'US' }"
                placeholder="486 854 7541"
              />
            </b-form-group>
          </b-col>

          <!-- Type -->
          <b-col lg="2" md="2" sm="12">
            <b-form-group label="Type" :label-for="`number-type-${index}`">
              <v-select
                :id="`number-type-${index}`"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :value="item.type"
                :options="[
                  { label: 'Cell', value: 'cell' },
                  { label: 'Home', value: 'home' },
                  { label: 'Work', value: 'work' },
                ]"
                class="w-100"
                :clearable="false"
                :reduce="(val) => val.value"
                @input="(val) => (item.type = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col lg="2" md="2" class="mb-50">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon icon="XIcon" class="mr-25" />
              <span>Delete</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>

      <b-row class="mt-2 w-100">
        <b-col cols="12" lg="12">
          <b-button
            variant="primary"
            class="float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="doSaveChanges"
          >
            Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="$router.back()"
          >
            <feather-icon icon="ArrowLeftIcon" />
            Return
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="repeateAgain">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Add New</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onMounted, onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import useProfilesList from '../../profile-list/useProfileList';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import Cleave from 'vue-cleave-component';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us';
import placementStoreModule from '../../placementStoreModule';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    ToastificationContent,
    Cleave,
    BInputGroupPrepend,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    profileData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    profileData: function () {},
  },
  data: () => {
    return {
      submit: { sync: false },

      phoneNumbers: [],
      nextTodoId: 1,
    };
  },
  methods: {
    doSaveChanges() {
      const pwd = this.profileData;
      const id = pwd.id;

      const phoneNumbers = this.phoneNumbers
        // .filter(num => num.number)
        .map((num) => {
          return {
            ...num,
            number: num.number.replaceAll(' ', ''),
          };
        });

      store
        .dispatch('app-placement/updateProfile', {
          id,
          profileData: { phoneNumbers },
        })
        .then((response) => {
          console.log(response.data);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profile Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit profile',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },

    repeateAgain() {
      this.phoneNumbers.push({ type: 'cell' });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.phoneNumbers.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    loadProfile() {
      store.dispatch('app-placement/fetchProfile', { id: this.profileDataInfo.id }).then((response) => {
        this.phoneNumbers = response.data.phoneNumbers.map((num) => {
          // if (num.number.startsWith('+1')) {
          //   num.number = num.number.substr(2);
          // }

          return num;
        });
        this.trAddHeight(this.$refs.row[0].offsetHeight * this.phoneNumbers.length);

        console.log(`Phone number registered`, this.phoneNumbers);
        this.initTrHeight();
      });
    },
  },
  created() {
    window.addEventListener('resize', this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight);
  },
  mounted() {
    this.loadProfile();
  },
  setup(props) {
    const profileDataInfo = ref(props.profileData);

    const PROFILE_APP_STORE_MODULE_NAME = 'app-placement';

    // Register module
    if (!store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROFILE_APP_STORE_MODULE_NAME, placementStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PROFILE_APP_STORE_MODULE_NAME);
      }
    });

    const { statusOptions } = useProfilesList();

    return {
      profileDataInfo,
      statusOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
