import { render, staticRenderFns } from "./ProfileEditTabNotes.vue?vue&type=template&id=153db2e0&scoped=true&"
import script from "./ProfileEditTabNotes.vue?vue&type=script&lang=js&"
export * from "./ProfileEditTabNotes.vue?vue&type=script&lang=js&"
import style0 from "./ProfileEditTabNotes.vue?vue&type=style&index=0&id=153db2e0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "153db2e0",
  null
  
)

export default component.exports