<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="ProfileIcon" size="19" />
      <h4 class="mb-0">Certificates</h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <!-- Form: Personal Info Form -->
      <b-form ref="form" @submit.prevent="repeateAgain">
        <h5 v-if="!certificates || certificates.length === 0" class="text-center">No certificates listed</h5>

        <!-- Row Loop -->
        <b-row v-else v-for="(item, index) in certificates" :id="item.id" :key="item.id" ref="row">
          <!-- Item Name -->
          <b-col lg="2" md="3" sm="12">
            <b-form-group label="Name" :label-for="`certificate-name-${index}`">
              <b-form-input
                :id="`certificate-name-${index}`"
                type="text"
                placeholder="CompTIA Security+"
                v-model="item.name"
              />
            </b-form-group>
          </b-col>

          <!-- Description -->
          <b-col lg="3" md="4" sm="12">
            <b-form-group label="Description" :label-for="`certificate-description-${index}`">
              <b-form-textarea
                :id="`certificate-description-${index}`"
                type="text"
                placeholder="Description"
                v-model="item.description"
              />
            </b-form-group>
          </b-col>

          <!-- Score -->
          <b-col lg="2" md="3" sm="12">
            <b-form-group label="Score" :label-for="`certificate-score-${index}`">
              <b-form-input :id="`certificate-score-${index}`" type="text" placeholder="300" v-model="item.score" />
            </b-form-group>
          </b-col>

          <!-- Expires -->
          <b-col lg="3" md="4" sm="12">
            <b-form-group label="Expires At" :label-for="`certificate-expiresAt-${index}`">
              <b-form-datepicker
                class="w-100"
                :id="`certificate-expiresAt-${index}`"
                :value="item.expiresAt"
                @input="(val) => (item.expiresAt = val)"
              />
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col lg="2" md="3" sm="12" class="mb-50">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon icon="XIcon" class="mr-25" />
              <span>Delete</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>

      <b-row class="mt-2 w-100">
        <b-col cols="12" lg="12">
          <b-button
            variant="primary"
            class="float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="doSaveChanges"
          >
            Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="$router.back()"
          >
            <feather-icon icon="ArrowLeftIcon" />
            Return
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="repeateAgain">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Add New</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BFormDatepicker,
  BFormTextarea,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onMounted, onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import useProfilesList from '../../profile-list/useProfileList';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import placementStoreModule from '../../placementStoreModule';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormTextarea,
    BButton,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    profileData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    profileData: function () {},
  },
  data: () => {
    return {
      submit: { sync: false },

      certificates: [],
      nextTodoId: 1,
    };
  },
  methods: {
    doSaveChanges() {
      const pwd = this.profileData;
      const id = pwd.id;

      const parsed = this.certificates
        .filter((adr) => adr.name)
        .map((cert) => {
          return {
            name: cert.name,
            description: cert.description,
            score: cert.score,
            expiresAt: cert.expiresAt,
          };
        });

      store
        .dispatch('app-placement/updateProfile', {
          id,
          profileData: { certificates: parsed },
        })
        .then((response) => {
          this.loadProfile();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profile Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit profile',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },

    repeateAgain() {
      this.certificates.push({ certificate: {} });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.certificates.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    loadProfile() {
      this.$store
        .dispatch('app-placement/fetchProfile', {
          id: this.profileDataInfo.id,
          populate: 'student profile',
        })
        .then((response) => {
          const json = response.data;

          this.certificates = json.certificates;

          if (!this.certificates) this.certificates = [];

          this.initTrHeight();
        });
    },
  },
  created() {
    window.addEventListener('resize', this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight);
  },
  mounted() {
    this.loadProfile();
  },
  setup(props) {
    const profileDataInfo = ref(props.profileData);

    const PROFILE_APP_STORE_MODULE_NAME = 'app-placement';

    // Register module
    if (!store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROFILE_APP_STORE_MODULE_NAME, placementStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PROFILE_APP_STORE_MODULE_NAME);
      }
    });

    const { statusOptions } = useProfilesList();

    return {
      profileDataInfo,
      statusOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
