<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <h4 class="mb-0">Profile Information</h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <!-- Form: Personal Info Form -->
      <b-form class="mt-1">
        <b-row class="mt-1">
          <!-- Field: Profile First Name -->
          <b-col cols="12" lg="3" md="6" xs="12">
            <b-form-group label="First Name" label-for="profile-first-name">
              <b-form-input id="profile-first-name" required v-model="profileDataInfo.firstName" />
            </b-form-group>
          </b-col>
          <!-- Field: Profile Last Name -->
          <b-col cols="12" lg="3" md="6" xs="12">
            <b-form-group label="Last Name" label-for="profile-last-name">
              <b-form-input id="profile-last-name" required v-model="profileDataInfo.lastName" />
            </b-form-group>
          </b-col>
          <!-- Field: Profile Email -->
          <b-col cols="12" lg="3" md="6" xs="12">
            <b-form-group label="Email" label-for="profile-email">
              <b-form-input id="profile-email" required v-model="profileDataInfo.email" />
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col cols="12" lg="3" md="6" xs="12">
            <b-form-group label="Status" label-for="profile-status">
              <v-select
                v-model="profileDataInfo.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :clearable="false"
                required
                input-id="profile-status"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <!-- Form: Personal Info Form -->

      <b-row class="mt-2 w-100">
        <b-col cols="12" lg="12">
          <b-button
            variant="primary"
            class="float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="doSaveChanges"
          >
            Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="$router.back()"
          >
            <feather-icon icon="ArrowLeftIcon" />
            Return
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import useProfilesList from '../../profile-list/useProfileList';
import placementStoreModule from '../../placementStoreModule';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    ToastificationContent,
  },
  props: {
    profileData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    profileData: function () {
      this.inheritSelects();
    },
  },
  data: () => {
    return {
      submit: {
        sync: false,
      },
    };
  },
  methods: {
    inheritSelects() {
      if (this.profileData.status && !this.profileData.status.value) {
        this.profileDataInfo.status = this.statusOptions.filter((s) => s.value === this.profileData.status)[0];
      }
    },
    doSaveChanges() {
      const pwd = this.profileDataInfo;
      const id = pwd.id;

      let saved = ['firstName', 'lastName', 'email', 'status'];

      for (const key of Object.keys(pwd)) {
        if (!saved.includes(key)) delete pwd[key];
        else if (pwd[key].value) pwd[key] = pwd[key].value;
      }

      this.$store
        .dispatch('app-placement/updateProfile', { id, profileData: pwd })
        .then((response) => {
          console.log(response.data);

          this.$emit('update:profile-data', response.data);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profile Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit profile',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  mounted() {
    this.inheritSelects();
  },
  setup(props) {
    const profileDataInfo = ref(props.profileData);

    const PROFILE_APP_STORE_MODULE_NAME = 'app-placement';

    // Register module
    if (!store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) {
      store.registerModule(PROFILE_APP_STORE_MODULE_NAME, placementStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROFILE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PROFILE_APP_STORE_MODULE_NAME);
      }
    });

    const { statusOptions } = useProfilesList();

    return {
      profileDataInfo,
      statusOptions,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
